<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"/>
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"/>
      <v-container class="container--fluid grid-list-md text-center">
        <h1 data-cy="account-title">Accounts</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <div>
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      data-cy="search-account"
                      single-line
                      hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2">
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      v-if="hasPermission('ROLE_CREATE_ACCOUNT')"
                      class="mt-2"
                      color="success"
                      data-cy="create-account"
                      :block="$vuetify.breakpoint.mdAndDown"
                      @click.stop="showSavingsAccountForm('CREATED')">
                      Create Account
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
              <savings-account-form
                ref="form"
                :selected-item="selectedSavingsAccount"
                :is-show="showFormDialog"
                :form-type="savingsAccountFormType"
                :members="members"
                :wallets="wallets"
                :accounts="displaySavingsAccounts"
                v-on:close-dialog="closeDialog"
                v-on:save-savings-account="saveSavingsAccount"/>
              <savings-account-dot-status-update-form
                ref="form"
                :selected-item="selectedSavingsAccount"
                :is-show="isShowStatusUpdateForm"
                v-on:close-dialog="isShowStatusUpdateForm = false"
                v-on:save-savings-account="saveSavingsAccountStatus"/>
            </v-container>
          </v-card-title>
        </div>
      </v-card>
      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="displaySavingsAccounts"
        :search="search"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalSavingsAccounts">
        <template v-slot:header="{ props: { headers } }">
          <thead>
          <tr :colspan="headers.length">
            <th v-for="header in headers" :key="header.text">
              <span class="d-flex justify-center">{{ header.text }}</span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
          <tr :colspan="headers.length" v-for="item in items" :key="item.id">
            <td>
              <span class="d-flex justify-center"> {{ item.accountNo }} </span>
            </td>
            <td>
              <span class="d-flex justify-center"> {{ item.externalId }} </span>
            </td>
            <td>
              <span class="d-flex justify-center"> {{ item.name }} </span>
            </td>
            <td>
              <span class="d-flex justify-center"> {{ item.lastUpdated }} </span>
            </td>
            <td>
              <span class="d-flex justify-center"> {{ item.memberName }} </span>
            </td>
            <td>
                <span class="d-flex justify-center">
                  ₱ {{ formatMoney(item.accountBalance) }}
                </span>
            </td>
            <td>
               <span v-if="item.subStatus.value === 'None'"
                     class="d-flex justify-center"> {{ item.status.value }} </span>
              <span v-else
                    class="d-flex justify-center"> {{ item.subStatus.value }} </span>
            </td>
            <td>
                <span class="d-flex justify-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn fab dark x-small color="green" class="mr-2"
                             v-bind="attrs"
                             v-on="on"
                             @click.stop="viewDetails(item)">
                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="hasPermission('ROLE_READ_TRANSACTIONS')"
                             fab dark x-small color="green" class="mr-2"
                             v-bind="attrs"
                             v-on="on"
                             :to="{
                          name: 'account-transaction',
                          params: {
                            id: item.id,
                            status: item.status.active
                              ? !item.subStatus.block || item.subStatus.block === 'false'
                                ? 'Active'
                                : 'Blocked'
                              : 'Inactive',
                          },
                        }">
                        <v-icon dark medium>mdi-table</v-icon>
                      </v-btn>
                    </template>
                    <span>Transactions</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="hasPermission('ROLE_UPDATE_ACCOUNT')"
                             fab dark x-small color="green" class="mr-2"
                             v-bind="attrs"
                             v-on="on"
                             @click.stop="showSavingsAccountForm('UPDATE', item)">
                        <v-icon dark medium>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-if="hasPermission('ROLE_UPDATE_ACCOUNT_STATUS')"
                             fab dark x-small color="green"
                             v-bind="attrs" v-on="on"
                             @click.stop="showStatusUpdateForm(item)">
                        <v-icon dark medium>mdi-thumbs-up-down</v-icon>
                      </v-btn>
                    </template>
                    <span>Update Status</span>
                  </v-tooltip>
                </span>
            </td>
          </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import SavingsAccountForm from "@/views/components/members-account/SavingsAccountForm.vue";
import SavingsAccountDotStatusUpdateForm
  from "@/views/components/members-account/StatusUpdateForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import {
  INTEREST_CALCULATION_METHODS,
  INTEREST_COUMPOUNDING_PERIODS,
  INTEREST_POSTING_PERIODS,
  LOCKIN_PERIOD_FREQUENCY_TYPE,
} from "@/constants/savings_product.js";
import {mapGetters} from "vuex";

export default {
  name: "SavingsAccounts",
  components: {
    Loading,
    SavingsAccountForm,
    SavingsAccountDotStatusUpdateForm,
    DetailsForm,
  },
  activated() {
    console.log("activated");
  },
  deactivated() {
    console.log("deactivated");
  },
  data() {
    return {
      //filter/search variables
      search: "",
      filterQry: {
        qryName: "",
      },
      headers: [],
      displaySavingsAccounts: [],
      currentAccounts: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      savingsAccountFormType: "CREATE",
      selectedSavingsAccount: {},
      members: [],
      wallets: [],
      totalSavingsAccounts: 0,
      options: {},
      details: {
        title: "",
        item: {},
      },
      isShowDetails: false,
      showFormDialog: false,
      isShowStatusUpdateForm: false,
      isHtml: false,
    };
  },
  watch: {
    options: {
      handler() {
        console.log("options handler");
        this.queryData();
        this.setData();
      },
    },
    params: {
      handler: function handler() {
        this.filter();
      },
    },
    deep: true,
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
    params(nv) {
      return {
        ...this.options,
        query: this.search,
      };
    },
  },
  async mounted() {
    this.setHeaders();
  },
  methods: {
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true;
      }
      return false;
    },
    filter() {
      let search = this.search.trim().toLowerCase();
      if (search) {
        this.displaySavingsAccounts = this.currentAccounts
          .filter((item) => {
            return Object.values(item).join(",").toLowerCase().includes(search);
          });
      } else {
        this.displaySavingsAccounts = this.currentAccounts
        ;
      }
    },
    searchTest(value, search, item) {
      console.log("Test Search" + value + "||" + search)
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    async queryData(isSearch = false) {
      this.loading = true;
      if (!this.displaySavingsAccounts.length) {
        this.loadingDialog = true;
      }
      if (isSearch) {
        this.options.page = 1;
      }
      let offset = (this.options.page - 1) * this.options.itemsPerPage;
      try {
        const savingsAccountListResponse = await API.searchSavingsAccounts(
          offset,
          this.options.itemsPerPage
        );
        if (!savingsAccountListResponse || savingsAccountListResponse.error) {
          //error getting data
          console.log(`${savingsAccountListResponse.error}`);
        } else {
          this.totalSavingsAccounts = savingsAccountListResponse.totalSize;
          this.displaySavingsAccounts = savingsAccountListResponse.accounts;
          this.currentAccounts = this.displaySavingsAccounts;
        }
        this.loading = false;
        this.loadingDialog = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    // filter() {
    //   let search = this.search.trim().toLowerCase();
    //   if (search) {
    //     this.displaySavingsAccounts = this.currentTransactions.filter((item) => {
    //       return Object.values(item).join(",").toLowerCase().includes(search);
    //     });
    //   } else {
    //     this.displaySavingsAccounts = this.currentTransactions;
    //   }
    // },
    async setData() {
      try {
        const listResponse = await API.getMembers();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          this.members = Object.keys(listResponse)
            .map((key) => {
              if (listResponse[key].status.value === "Active") {
                // filter 'Active' client status only
                return listResponse[key];
              }
            })
            .filter((notUndefined) => notUndefined !== undefined);
          console.log(this.members);
        }
      } catch (e) {
        console.log(e);
      }
      try {
        const listResponse = await API.getSavingsProducts();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          this.wallets = Object.keys(listResponse).map((key) => {
            return listResponse[key];
          });

          console.log(this.wallets);
        }
      } catch (e) {
        console.log(e);
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Account #",
          value: "accountNo",
        },
        {
          text: "External ID",
          value: "externalId",
        },
        {
          text: "Account Name",
          value: "name",
        },
        {
          text: "Last Active",
          value: "lastUpdated",
        },
        {
          text: "Client",
          value: "memberName",
        },
        {
          text: "Balance",
          value: "accountBalance",
        },
        {
          text: "Status",
          value: "status.value",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    showSavingsAccountForm(what, item = {}) {
      this.savingsAccountFormType = what;
      this.selectedSavingsAccount = item;
      this.showFormDialog = true;
    },
    showStatusUpdateForm(item = {}) {
      this.selectedSavingsAccount = item;
      this.isShowStatusUpdateForm = true;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "CREATED" || type === "UPDATE" || type === "STATUS") {
        this.showFormDialog = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    async saveSavingsAccountStatus(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      this.loadingMessage = 'Updating savings account\'s status';
      setTimeout(async () => {
        const updateStatusResponse = await API.updateSavingsAccountStatus(value);
        this.loadingFinished = true;
        if (!updateStatusResponse || updateStatusResponse.error) {
          this.isHtml = false;
          this.loadingMessage = updateStatusResponse.error.message;
        } else {
          this.loadingMessage = 'Successfully updated savings account\'s status';
          this.showFormDialog = false;
          this.$refs.form.resetForm();
        }
      }, 2000);
    },
    async saveSavingsAccount(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        if (value.formType === "CREATED") {
          this.loadingMessage = `Creating new savings account`;
          setTimeout(async () => {
            const createResponse = await API.createSavingsAccounts(value);
            this.loadingFinished = true;
            if (!createResponse || createResponse.error) {
              this.isHtml = false;
              this.loadingMessage = createResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created savings account`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "UPDATE") {
          this.loadingMessage = `Updating savings account`;
          setTimeout(async () => {
            const updateResponse = await API.updateSavingsAccounts(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully updated savings account`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "STATUS") {
          this.loadingMessage = `Updating savings account's status`;
          setTimeout(async () => {
            const updateStatusResponse = await API.updateSavingsAccountStatus(value);
            this.loadingFinished = true;
            if (!updateStatusResponse || updateStatusResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateStatusResponse.error.message;
            } else {
              this.loadingMessage = `Successfully updated savings account's status`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else {
          this.loadingMessage = "Invalid command";
          this.loadingFinished = true;
        }
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
      }
    },
    viewDetails(details) {
      this.details.title = details.externalId;
      this.details.item = Object.assign({}, details);
      this.details.item.currency = details.currency
        ? details.currency.displayLabel
        : undefined;
      this.details.item.subStatus = details.subStatus
        ? details.subStatus.value
        : undefined;
      this.details.item.status = details.status ? details.status.value : undefined;
      this.details.item.accountBalance = details.accountBalance
        ? `₱ ${this.formatMoney(details.accountBalance)}`
        : 0;
      this.details.item.availableBalance = details.availableBalance
        ? `₱ ${this.formatMoney(details.availableBalance)}`
        : 0;
      this.details.item.lockinPeriodFrequencyType = details.lockinPeriodFrequencyType
        ? LOCKIN_PERIOD_FREQUENCY_TYPE.find(
          (x) => x.value === details.lockinPeriodFrequencyType
        ).text
        : "";
      this.details.item.interestCalculationType = details.interestCalculationType
        ? INTEREST_CALCULATION_METHODS.find(
          (x) => x.value === details.interestCalculationType
        ).text
        : "";
      this.details.item.interestCompoundingPeriodType = details.interestCompoundingPeriodType
        ? INTEREST_COUMPOUNDING_PERIODS.find(
          (x) => x.value === details.interestCompoundingPeriodType
        ).text
        : "";
      this.details.item.interestPostingPeriodType = details.interestPostingPeriodType
        ? INTEREST_POSTING_PERIODS.find(
          (x) => x.value === details.interestPostingPeriodType
        ).text
        : "";
      this.isShowDetails = true;
    },
    formatMoney(money) {
      return money ? money.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : 0;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
</style>
